import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import Content from './Content';
import Footer from './Footer';
import { useSelector } from 'react-redux';
import { ChatInterface } from '../utils/interfaces';
import AnnouncementBanner from '../component/announcement/AccouncementBanner';
import { isIOS, isMobile } from 'react-device-detect';
import QueueModal from './Modal/QueueModal';
import classNames from 'classnames';

interface Props {
    debug?: string;
    height: number;
    isKeyBoardOpen: boolean;
    keyBoardHeight: number;
}

const Container = (props: Props) => {
    const username = useSelector(
        (state: { session: { username: string } }) => state.session.username
    );
    const containerRef = useRef<any>();
    const [bannerHeight, setBannerHeight] = useState(0);
    // const [height, setHeight] = useState(window.innerHeight * 0.99);
    const [size, setSize] = useState({
        ih: 0,
        iw: 0,
        vh: 0,
        vw: 0,
    });

    const announcementIsEnabled = useSelector(
        (state: { settings: { announcementIsEnabled: boolean } }) =>
            state.settings.announcementIsEnabled
    );

    // useEffect(() => {
    //     window.scrollTo(0, 0);
    //     document.body.scrollTop = 0;
    //     const handleResize = () => {
    //         if(window.innerWidth>768) {
    //             setHeight(window.innerHeight*0.9)
    //         }
    //         else{
    //             setHeight(window.innerHeight)
    //         }
    //         console.log(
    //             window.innerHeight,
    //             window.innerWidth,
    //             window.visualViewport.height,
    //             window.visualViewport.width
    //         );
    //         setSize({
    //             ...size,
    //             ih: window.innerHeight,
    //             iw: window.innerWidth,
    //             vh: window.visualViewport.height,
    //             vw: window.visualViewport.width,
    //         });
    //         window.scrollTo(0, 0);
    //         document.body.scrollTop = 0;
    //     };

    //     window.addEventListener('resize', handleResize);

    //     return () => {
    //         window.removeEventListener('resize', handleResize);
    //     };
    // });

  const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    setIsIOS(
      userAgent.indexOf("iphone") !== -1 ||
        userAgent.indexOf("ipad") !== -1 ||
        userAgent.indexOf("ipod") !== -1
    );
    // console.log("isIOS == ", isIOS, userAgent);
  }, []);

  const iosSpecialClasses = classNames({
    "fixed top-0 w-full overflow-hidden": isIOS,
  });

  if (isIOS) {
    return (
      <>
        {username && (
          <div
            style={{ opacity: 0.99 }}
            ref={containerRef}
            className="fixed flex flex-col md:relative w-full h-full top-0 md:h-90pc small:w-128 medium:w-196 large:w-256 3xl:w-320 rounded-md shadow-lg overflow-hidden duration-300"
          >
            <div className={iosSpecialClasses}>
              <Header debug={props.debug} setBannerHeight={setBannerHeight} />
              {announcementIsEnabled && <AnnouncementBanner />}
              <Content height={props.height} bannerHeight={bannerHeight} isIOS={true} isKeyBoardOpen={props.isKeyBoardOpen} keyBoardHeight={props.keyBoardHeight}/>
              <Footer debug={props.debug} />
            </div>
            <QueueModal height={props.height} />
          </div>
        )}
      </>
    );
  } else
    return (
        <>
            {username && (
                    <div
                        style={{opacity: 0.99}}
                        ref={containerRef}
                        className="fixed flex flex-col md:relative w-full h-full bottom-0 md:h-90pc small:w-128 medium:w-196 large:w-256 3xl:w-320 rounded-md shadow-lg overflow-hidden duration-300"
                    >
                        {/* <div className="absolute m-auto z-50 bg-white">
            {JSON.stringify(size)}
        </div> */}
                        <Header
                            debug={props.debug}
                            setBannerHeight={setBannerHeight}
                        />
                        {announcementIsEnabled && <AnnouncementBanner />}
                        <Content
                            height={props.height}
                            bannerHeight={bannerHeight}
                            isIOS={false}
                            isKeyBoardOpen={props.isKeyBoardOpen}
                            keyBoardHeight={props.keyBoardHeight}
                        />
                        <Footer debug={props.debug} />
                        <QueueModal height={props.height} />
                    </div>
            )}
        </>
    );
};

Container.propTypes = {};

export default Container;
