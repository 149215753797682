export const getOS = (navigator: any) => {
    if (navigator.userAgent.indexOf('Win') != -1) return 'Windows';
    else if (navigator.userAgent.indexOf('Mac') != -1) return 'MacOS';
    else if (navigator.userAgent.indexOf('X11') != -1) return 'UNIX';
    else if (navigator.userAgent.indexOf('Linux') != -1) return 'Linux';
    else return "Unknown OS";
};

export const checkIsAffectedIosBrowsers = (navigator: any)=> {
    const userAgent = navigator.userAgent;

    // Check for Brave
    if (userAgent.includes("Brave")) {
        return false; // Brave
    }
    // Check for Edge on iOS
    if (userAgent.includes("EdgiOS")) {
        return true; // Edge on iOS
    }
    // Check for Chrome on iOS
    if (userAgent.includes("CriOS")) {
        return true; // Chrome on iOS
    }

    if (userAgent.includes("Safari")){
        return true;
    }

    return false; // None of the above
}

